import { createSelector } from 'reselect';

const selectStudentQuestionsState = (state) => state.studentQuestions;

export const selectStudentQuestions = createSelector(
  [selectStudentQuestionsState],
  (studentQuestions) => {
    // Assuming 'studentQuestionsLists' is a part of the studentQuestions state
    // and we need to return only the list.
    return studentQuestions.studentQuestionsLists || [];
  }
);
