import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { register } from "./utils";

const StudentRegister = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [studentId, setStudentId] = useState(
    sessionStorage.getItem("studentId") || ""
  );

  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
        if(password !== confirmPassword){
            alert('Password and Confirm password do not match!!!');
            setLoading(false);

        } else {

            register(email, name, password, "student").then((res) => {
                if (res.data.success) {
                    console.log(res.data.data.user);
                    sessionStorage.setItem("studentId", res.data.data.user._id);
                    sessionStorage.setItem("studentName", res.data.data.user.name);
                    navigate("/student");
                } else {
                    alert(res.data.message);
                    setLoading(false);
                }
                // sessionStorage.setItem("studentId", 12);
            });
        }
    } catch (err) {
      setError("Login failed. Please check your credentials.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if(studentId !== ''){
      navigate('/student')
    }
  }, [])

  return (
    <div className="login_wrapper">
      <div className="left_section">
        <div className="left_header">
          <div className="right_image">
            <img className="logo logo_login" src="../education.png" />
          </div>
          <div className="left_image">
            <img className="logo logo_login" src="../dreamit.png" />
          </div>
        </div>
        <div className="main_logo">
          <img className="logo" src="../logo_s2h.png" />
          <h1 className="student_title"> الإصدار الأول / version 1</h1>
        </div>
      </div>
      <div className="right_section">
        <form className="form" onSubmit={handleRegister}>
          <div className="form_section">
            <h1 className="student_title">Register</h1>
            <div>
              <input
                type="text"
                className="form_input"
                value={name}
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div>
              <input
                type="email"
                className="form_input"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Password"
                className="form_input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Confirm Password"
                className="form_input"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div className="form_button_section">
              <button className="button button_small" type="submit" disabled={loading}>
                {loading ? "Register in..." : "Register"}
              </button>
            </div>
          </div>
          <div className="form_footer">
            Already have an account ?{" "}
            <Link className="link" to="/student-login">
              Login here
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StudentRegister;
