import React from 'react'

const Loading = () => {
  return (
    <div className='loader_container'>
        <div className="loader"></div>
    </div>
  )
}

export default Loading
