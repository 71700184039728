import React, { useEffect, useRef, useState } from 'react';
import { getLastNWords, speakMessage, translate } from './utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSocket } from './Context/SocketContext';

const Lecture = () => {

    const [message, setMessage] = useState('');
    const [enableVoice, setEnableVoice] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const enableLangRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const socket = useSocket();

    const lang = sessionStorage.getItem('translatedLang') || 'ar'
    const studentId = sessionStorage.getItem("studentId");


    const EnableVoice = (value) => {
        enableLangRef.current.value = value
        setEnableVoice(!enableVoice);
    }


    useEffect(() => {
        if(studentId == null || studentId == ''){
            navigate('/student-login');
        }
    }, [])



    useEffect(() => {

        // Check if we should show the message
        if (location.state?.showMessage) {
            setShowMessage(true);

            // Hide the message after 3 seconds
            const timer = setTimeout(() => {
                setShowMessage(false);
            }, 3000);

            // Cleanup the timer on unmount
            return () => clearTimeout(timer);
        }
    }, [location.state]);

    useEffect(() => {
        if (socket) {

            socket.on("/student/new_teacher_text", (data) => {
                let receivedData = JSON.parse(data);
                console.log(receivedData.language);
                translate(receivedData.message, receivedData.language.split('-')[0], lang).then(res => {
                    setMessage(getLastNWords(res, 10));
                    if (enableLangRef && enableLangRef.current && enableLangRef.current.value) {
                        navigator.mediaDevices.getUserMedia({ audio: true })
                            .then(function (stream) {
                                console.log('You let me use your mic!')
                                speakMessage(res, lang)
                            })
                            .catch(function (err) {
                                console.log('No mic for you!')
                            });
                    } else {
                        console.log('voice disable');
                    }
                })

            });

            const userAgent = navigator.userAgent;

            // OS Information (basic parsing)
            const osVersion = /Windows/.test(userAgent)
              ? "Windows"
              : /Mac/.test(userAgent)
              ? "MacOS"
              : /Android/.test(userAgent)
              ? "Android"
              : /iPhone|iPad|iPod/.test(userAgent)
              ? "iOS"
              : "Unknown";
      
            // Platform information from navigator
            const deviceModel = navigator.platform || "Unknown";

            socket.emit(
                "/student/new_user_connected",
                JSON.stringify({
                  userId: studentId,
                  platform: "web",
                  deviceModel: deviceModel,
                  osVersion: osVersion,
                  appVersion: "1.0.0",
                })
              );

            // Socket event listeners for receiving audio chunks
            socket.on('new-audio-stream', (data) => {
                console.log('event received ', enableVoice);
                if (enableVoice && lang === 'ar') {
                    console.log('play audio');

                    playAudio([data]);
                }
            });
        }

        return () => {
            if (socket) {

                const userAgent = navigator.userAgent;

                // OS Information (basic parsing)
                const osVersion = /Windows/.test(userAgent)
                  ? "Windows"
                  : /Mac/.test(userAgent)
                  ? "MacOS"
                  : /Android/.test(userAgent)
                  ? "Android"
                  : /iPhone|iPad|iPod/.test(userAgent)
                  ? "iOS"
                  : "Unknown";
          
                // Platform information from navigator
                const deviceModel = navigator.platform || "Unknown";
    
                socket.emit(
                    "/student/new_user_connected",
                    JSON.stringify({
                      userId: studentId,
                      platform: "web",
                      deviceModel: deviceModel,
                      osVersion: osVersion,
                      appVersion: "1.0.0",
                    })
                  );


                socket.off('new-stream');
                socket.off('new-audio-stream');
            }
        };
    }, [enableVoice, socket]);

    // Function to play received audio stream
    const playAudio = async (audioData) => {
        if (audioData.length > 0 && enableLangRef && enableLangRef.current && enableLangRef.current.value) {
            console.log('playing audio')
            try {
                await new Promise((resolve, reject) => {
                    const audioBlob = new Blob(audioData, { type: 'audio/wav' });
                    const audioURL = URL.createObjectURL(audioBlob);
                    const audio = new Audio(audioURL);
                    audio.play().then(resolve).catch(reject => {
                        console.log(reject)
                    });
                })
            } catch (error) {
                console.error('Permission audio denied:', error);
            }
        }
    };

    return (
        <div className="lecture_wrapper"

        >
            <div className="name_input_container">
                <Link className='back_btn' to="/student">
                    <img className='icon' src='../back.png' alt="Back" />
                </Link>
              
            </div>
            {showMessage && <div className="className">
                <h1>
                    Your message has been successfully sent to the teacher!!!
                </h1>
            </div>}
            <div
                className="text-message"
            >
                {message}
            </div>

            <Link className="message_button_container" to="/message">
                <img className='message_button' src="../chat.png" alt="" srcset="" />
            </Link>
            <div className="footer">
                <div></div>
            </div>
        </div >
    );
};

export default Lecture;
