import { useEffect, useRef, useState } from "react";

const Timer = () => {
    const timeRef = useRef(0);
    const [, forceUpdate] = useState(0); // State just to force re-render when needed

    useEffect(() => {
        const intervalId = setInterval(() => {
            timeRef.current += 1;
            forceUpdate(timeRef.current); // Force re-render occasionally if needed to display updated time
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const hours = Math.floor(timeRef.current / 3600);
    const minutes = Math.floor((timeRef.current % 3600) / 60);
    const seconds = timeRef.current % 60;

    return (
        <p className="stopwatch-time">
            {hours}:{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
        </p>
    );
};

export default Timer;

