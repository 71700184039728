import React, { useEffect, useRef, useState } from "react";
import { guestLogin, updateProfile } from "./utils";
import { useNavigate } from "react-router-dom";

const Screen = () => {
  const [translatedLang, setTranslatedLang] = useState(() => {
    // Retrieve the stored language from sessionStorage, or default to 'hi'
    return sessionStorage.getItem("translatedLang") || "ar";
  });
  const [studentName, setStudentName] = useState(
    sessionStorage.getItem("studentName") || ""
  );
  const [studentId, setStudentId] = useState(
    sessionStorage.getItem("studentId") || ""
  );

  const transLangRef = useRef(null);

  const navigate = useNavigate();

  const handleNameChange = (e) => {
    setStudentName(e.target.value);
    sessionStorage.setItem("studentName", e.target.value);
  };
  
  const handleJoinLecture = (e) => {
    if(studentId == ''){

      guestLogin(studentName, "student").then((res) => {
        if(res.data.success){
          console.log(res.data.data.user)
          setStudentName(res.data.data.user.name);
          sessionStorage.setItem("studentName", res.data.data.user.name);
          sessionStorage.setItem("studentId", res.data.data.user._id);
          navigate('/lecture');
        } else {
          alert(res.data.message)
        }
        // sessionStorage.setItem("studentId", 12);
      });
    
    } else {

      updateProfile(studentName, studentId).then((res) => {
        if(res.data.success){
          console.log(res.data.data.user)
          setStudentName(res.data.data.user.name);
          sessionStorage.setItem("studentName", res.data.data.user.name);
          sessionStorage.setItem("studentId", res.data.data.user._id);
          navigate('/lecture');
        } else {
          alert(res.data.message)
        }
        // sessionStorage.setItem("studentId", 12);
      });

    }
  };

  const handleLanguageChange = (e) => {
    setTranslatedLang(e.target.value);
    sessionStorage.setItem("translatedLang", e.target.value);
    transLangRef.current.value = e.target.value;
  };

  useEffect(() => {
    if(studentId == null || studentId == ''){
      navigate('/student-login')
    }
  }, [])

  return (
    <>
      <div className="logo_container full_width">
        <h1 className="student_title">Control Panel</h1>
        <img className="logo" src="../logo_s2h.png" />
        <h1 className="student_title">لوحة تحكم النظارة</h1>
      </div>
      <input
        type="text"
        className="name_input"
        onChange={handleNameChange}
        value={studentName}
        placeholder="Enter Your Name Here"
      />
      <div>
        <label className="label_language" htmlFor="languageDropdown">
          {" "}
          إختر اللغة / Select Language:{" "}
        </label>
        <select
          id="languageDropdown"
          onChange={handleLanguageChange}
          value={translatedLang}
          ref={transLangRef}
          className="select"
        >
          <option value="en">English</option>
          <option value="ar">عربي</option>
          <option value="es">Española</option>
          <option value="sh">中国人</option>
          <option value="fr">Français</option>
          <option value="hi">हिंदी</option>
          <option value="de">Deutsch</option>
          <option value="es-MX">Mexican</option>
          <option value="ja">日本語 (Nihongo)</option>
          <option value="ko">한국어 (Hangugeo)</option>
          <option value="ru">Русский (Russkiy)</option>
          <option value="pt">Português</option>
          <option value="it">Italiano</option>
          <option value="nl">Nederlands</option>
          <option value="el">Ελληνικά (Elliniká)</option>
          <option value="tr">Türkçe</option>
          <option value="he">עִבְרִית (Ivrit)</option>
          <option value="sv">Svenska</option>
          <option value="no">Norsk</option>
          <option value="fi">Suomi</option>
          <option value="pl">Polski</option>
          <option value="th">ภาษาไทย (Phasa Thai)</option>
          <option value="vi">Tiếng Việt</option>
          <option value="cs">Čeština</option>
          <option value="hu">Magyar</option>
          <option value="ro">Română</option>
          <option value="da">Dansk</option>
          <option value="fa">فارسی (Fārsi)</option>
          <option value="id">Bahasa Indonesia</option>
          <option value="ms">Bahasa Melayu</option>
          <option value="uk">Українська (Ukrainska)</option>
          <option value="sr">Српски (Srpski)</option>
          <option value="hr">Hrvatski</option>
          <option value="bg">Български (Balgarski)</option>
          <option value="sk">Slovenčina</option>
          <option value="lt">Lietuvių</option>
          <option value="lv">Latviešu</option>
          <option value="et">Eesti</option>
          <option value="sl">Slovenščina</option>
          <option value="tl">Filipino</option>
          <option value="sw">Kiswahili</option>
          <option value="af">Afrikaans</option>
          <option value="is">Íslenska</option>
          <option value="ga">Gaeilge</option>
          <option value="cy">Cymraeg</option>
          <option value="eu">Euskara</option>
          <option value="gl">Galego</option>
          <option value="ca">Català</option>
          <option value="mt">Malti</option>
          <option value="lb">Lëtzebuergesch</option>
          <option value="mk">Македонски (Makedonski)</option>
          <option value="my">Myanmabhasa</option>
          <option value="ne">नेपाली</option>
          <option value="si">සිංහල</option>
          <option value="ml">മലയാളം</option>
          <option value="pa">ਪੰਜਾਬੀ</option>
          <option value="mr">मराठी</option>
          <option value="gu">ગુજરાતી</option>
          <option value="kn">ಕನ್ನಡ</option>
          <option value="te">తెలుగు</option>
          <option value="ta">தமிழ்</option>
          <option value="uz">Oʻzbekcha</option>
          <option value="kk">Қазақ тілі</option>
          <option value="tk">Türkmençe</option>
          <option value="ky">Кыргызча</option>
          <option value="hy">Тоҷикӣ</option>
          <option value="ka">Հայերեն</option>
          <option value="az">ქართული</option>
          <option value="az">Azərbaycan dili</option>
          <option value="sq">Shqip</option>
          <option value="bs">Bosanski</option>
          <option value="cnr">Crnogorski</option>
          <option value="ht">Kreyòl ayisyen</option>
          <option value="jv">ꦧꦱꦗꦮ</option>
          <option value="su">Basa Sunda</option>
          <option value="fj">Na Vosa Vakaviti</option>
          <option value="sm">Gagana fa'a Sāmoa</option>
          <option value="to">Lea Faka-Tonga</option>
          <option value="mi">Te Reo Māori</option>
          <option value="hmn">Hmoob</option>
          <option value="km">Khmer</option>
          <option value="lo">Lao</option>
          <option value="am">Amarəñña</option>
          <option value="so">Soomaali</option>
          <option value="ti">Tigrinya</option>
          <option value="om">Afaan Oromoo</option>
          <option value="zu">isiZulu</option>
          <option value="xh">isiXhosa</option>
          <option value="yo">Yorùbá</option>
          <option value="ig">Asụsụ Igbo</option>
          <option value="sn">chiShona</option>
          <option value="ny">Chichewa</option>
          <option value="tn">Setswana</option>
          <option value="st">Sesotho</option>
          <option value="rw">Ikinyarwanda</option>
          <option value="ku">Kurmancî</option>
          <option value="ps">Paṣhto</option>
          <option value="prs">Darī</option>
          <option value="ug">Uyghurche</option>
          <option value="bo">Bod skad</option>
          <option value="ce">Нохчийн мотт</option>
          <option value="ba">Башҡорт теле</option>
          <option value="mn">Монгол</option>
          <option value="tt">Татар теле</option>
          <option value="udm">Удмурт кыл</option>
          <option value="chm">Марий йылме</option>
          <option value="sah">Саха тыла</option>
          <option value="kom">Коми кыв</option>
          <option value="khn">Хӑнты ясанг</option>
          <option value="yrk">Ненэцяʼ вада</option>
          <option value="ch">Chamorro</option>
          <option value="mh">Kajin Majel</option>
          <option value="pau">A tekoi er a Belau</option>
          <option value="tpi">Tok Pisin</option>
          <option value="bi">Bislama</option>
          <option value="tet">Tetun</option>
          <option value="tvl">Te Gana Tuvalu</option>
          <option value="Faka'uvea">Faka'uvea</option>
          <option value="ty">Reo Tahiti</option>
          <option value="gil">Taetae ni Kiribati</option>
          <option value="ha">Harshen Hausa</option>
          <option value="wo">Wolof</option>
          <option value="ln">Lingála</option>
        </select>
      </div>

      <div className="button_section" onClick={handleJoinLecture}>
        <div class="button-white" role="button">
          <img className="button_icon" src="../student.png" />
          <span className="button_text">الدخول للمحاضرة / Join Lecture</span>
        </div>
      </div>
    </>
  );
};

export default Screen;
