import { combineReducers } from 'redux';
import { StudentQuestionsReducer } from './studentQuestions/studentQuestions.reducer';


// reducer import

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  studentQuestions: StudentQuestionsReducer,
});

export default reducer;
