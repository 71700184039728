import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
    return (
            <div className='menu_wrapper'>
                <div className="menu">
                    <Link to="/student-login">
                        <button class="button" role="button">Receiver<br />
                            المستقبل
                        </button>
                    </Link>
                    <Link to="/teacher-login">
                        <button class="button" role="button">Sender <br />
                            المرسل</button>
                    </Link>
                </div>

                <div className="king_section">
                    <h3 className='king_text'>لمحادثة مباشر فردية. الرجاء الضغط علي الايقونه في الأسفل</h3>
                    <a class="king_button" href="http://king.dreamit.technology/">
                            <img className='king_button_icon' src='../king.svg' />
                    </a>

                </div>

            </div>
    )
}

export default Home
