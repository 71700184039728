import STUDENT_QUESTIONS_ACTION_TYPES from './studentQuestions.types';

export const STUDENT_QUESTIONS_INITIAL_STATE = {
  studentQuestionsLists: [],
};

export const StudentQuestionsReducer = (state = STUDENT_QUESTIONS_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case STUDENT_QUESTIONS_ACTION_TYPES.LOAD_STUDENT_QUESTIONS: {
      return { ...state, studentQuestionsLists: payload };
    }
    case STUDENT_QUESTIONS_ACTION_TYPES.SET_STUDENT_QUESTIONS: {
      return { ...state, studentQuestionsLists: [ ...state.studentQuestionsLists, payload] };
    }
    default:
      return state;
  }
};
