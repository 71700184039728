import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Dictaphone from "./Dictaphone";
import Screen from "./Screen";
import Home from "./Home";
import Lecture from "./Lecture";
import Teacher from "./Teacher";
import Message from "./Message";
import { Provider } from "react-redux";
import { Store } from "./store/Store";
import { SocketProvider } from "./Context/SocketContext";
import StudentLogin from "./StudentLogin";
import StudentRegister from "./StudentRegister";
import TeacherRegister from "./TeacherRegister";
import TeacherLogin from "./TeacherLogin";
// import TextToSpeechComponent from "./TextToSpeechComponent";

function App() {
  useEffect(() => {
    console.log("app");
  }, []);

  return (
    <Provider store={Store}>
      <SocketProvider>
        <div className="App">
          <div className="container">
            <Router>
              <Routes>
                <Route path="/student" element={<Screen />} />
                <Route path="/student-login" element={<StudentLogin />} />
                <Route path="/student-register" element={<StudentRegister />} />
                <Route path="/message" element={<Message />} />
                <Route path="/lecture" element={<Lecture />} />
                <Route path="/teacher-login" element={<TeacherLogin />} />
                <Route path="/teacher-register" element={<TeacherRegister />} />
                <Route path="/teacher" element={<Teacher />} />
                <Route path="/dictaphone" element={<Dictaphone />} />
                <Route path="/" element={<Home />} />
              </Routes>
            </Router>
          </div>
        </div>
      </SocketProvider>
    </Provider>
  );
}

export default App;
